/*
 * @Author: liyu
 * @Date: 2022-06-07 16:05:40
 * @LastEditTime: 2022-06-08 15:27:37
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/trust/privacy/index.tsx
 */
import React, { ReactNode } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { TrustCenterState } from '../center/index.zh'
import { CommonContentList } from 'components/Trust/Card/CommonCard'
import SectionContent from 'components/SectionContent'
import Layout from 'layouts/zh'
import * as styles from './index.module.less'

const Privacy: React.FC = (props) => {
  const { trustCenterYaml } = useStaticQuery(
    graphql`
      query {
        trustCenterYaml {
          privacy {
            title
            description
            key
            satitle
          }
          banner_bg_pc
          banner_bg_mb
          privacy_banner_info {
            content
            title
          }
          privacy_tab_list {
            is_active
            link
            name
          }
          bottom_card_title
          bottom_card_desc
          privacy_content_info {
            small_icon
            title
          }
          privacy_content_list {
            title
            icon
            content {
              content
            }
          }
        }
      }
    `,
  )

  const { privacy, privacy_tab_list, privacy_content_info, privacy_content_list, privacy_banner_info } = trustCenterYaml

  return (
    <Layout {...props}>
      <TrustCenterState
        trustCenterYaml={trustCenterYaml}
        TDK={privacy}
        bannerInfo={privacy_banner_info}
        tabList={privacy_tab_list}
      >
        <>
          <SectionContent className={styles.privacy} title={privacy_content_info.title}>
            <CommonContentList data={privacy_content_list} smallIcon={privacy_content_info.small_icon} />
          </SectionContent>
        </>
      </TrustCenterState>
    </Layout>
  )
}

export default Privacy
