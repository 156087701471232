import React from 'react';
import classnames from 'classnames';
import * as styles from './index.module.less';

const ContentPart = ({content, title, smallIcon}) => {
  return (
    <div className="w-11/12 mx-auto my-0 box-border lg:p-16">
      <p className={styles.contentTitle}>{title}</p>
        {
          content?.length > 0 ? content.map((item, index) => {
            return (
              <>
                {
                  item.title ? 
                    (
                      <div className={styles.smallTitle}>
                        <span>
                          {smallIcon && <img src={smallIcon} alt="title_icon"/>}
                        </span>
                        <span className={styles.smallTitleText}>{item.title}</span>
                      </div>
                    )
                  : null
                }
                {
                  item.content?.length > 0 ? item.content.map((itemSon, i) => {
                    return (
                      <div className={classnames(styles.contentText, styles.contentMarginBottom32 )} key={i} dangerouslySetInnerHTML={{__html: itemSon as string}}/> 
                    )
                  }): null
                }
              </>
            )
          }) : null
        }
    </div>
  )
}

const IconPart = ({icon}) => {
  return (
    <>
      {
        icon ? (
          <p className="w-11/12 mx-auto my-0 h-auto text-center mb-4">
            <img src={icon} alt="security_icon"/>
          </p>
        ) : null
      }
    </>
  )
}

const IconLeft = ({ dataInfo, smallIcon }) => {
  const { title, icon, content } = dataInfo;
  return (
    <>
      <ContentPart content={content} title={ title } smallIcon={ smallIcon }/>

      <IconPart icon={ icon }/>
    </>
  )
}

const IconRight = ({ dataInfo, smallIcon }) => {
  const { title, icon, content } = dataInfo;
  return (
    <>
      <IconPart icon={ icon }/>
      <ContentPart content={content} title={title} smallIcon={ smallIcon }/>
    </>
  )
}

export const CommonContentCard = ({ dataInfo, type, smallIcon }) => {
  return (
    <>
      <div className={classnames('hidden lg:flex justify-between items-center max-w-[1200px]')}>
        {type === 'L' && <IconLeft dataInfo={dataInfo} smallIcon={ smallIcon }/>}
        {
          type === 'R' && <IconRight dataInfo={dataInfo} smallIcon={ smallIcon}/>
        }
      </div>
      <div className="block w-11/12 mx-auto my-8 lg:hidden">
        <IconLeft dataInfo={dataInfo} smallIcon={ smallIcon }/>
      </div>
    </>
    
  )
}


export const calculateIconPosition = (index) => {
  let postion;
  if (index % 2 === 0) {
    postion = 'L'
  } else {
    postion = 'R'
  }

  return postion;
}
interface listProps {
  data?: any,
  smallIcon?: any
}

export const CommonContentList = ({ data, smallIcon }:listProps) => {
  return (
    <ul className="max-w-[1200px] mx-auto mt-0 mb-8 ">
      {
        data?.length > 0 && data.map((item,index) => {
          return (
            <li className={styles.commonContentItem} key={index}>
              <CommonContentCard dataInfo={item} type={calculateIconPosition(index)} smallIcon={ smallIcon }/>
            </li>
          )
        })
      }
    </ul>
  )
}